<template>
  <div>
  <div class="row">
    <div class="col-md-12">
        <div class="card card-outline card-primary">
              <div class="card-header">
                <h2 class="card-title">Umsätze Händler</h2>
                  <br/>
                  <br/>
                  <div class="row">
                    <div class="col-md-5">
                      <div class="form-group row">
                        <label class="col-md-3 col-form-label">Von:</label>
                        <div class="col-md-9">
                          <input type="date" class="form-control form-control-sm" v-model="params.start_date" />
                        </div>   
                      </div>
                    </div>

                    <div class="col-md-5">
                      <div class="form-group row">
                        <label class="col-md-3 col-form-label">Bis:</label>
                        <div class="col-md-9">
                          <input type="date" class="form-control form-control-sm" v-model="params.stop_date" />
                        </div>  
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-md-5">
                      <div class="form-group row">
                        <label class="col-md-3 col-form-label">Hersteller ausschließen</label>
                        <div class="col-md-9">
                          <Select2 v-model="params.excluded_producers" :options="producers" :settings="{ multiple: true , closeOnSelect : false , allowClear: true}"/>
                          <!-- <select multiple class="form-control form-control-sm" v-model="params.excluded_producers">
                            <option v-for="producer in producers" :key="producer.id" :value="producer.id">{{ producer.number }} - {{ producer.name }}</option>
                          </select> -->
                        </div>   
                      </div>
                    </div>

                    <div class="col-md-5">
                      <div class="form-group row">
                        <label class="col-md-3 col-form-label">Filiale</label>
                        <div class="col-md-9">
                          <select class="form-control form-control-sm" v-model="params.store_id">
                            <!-- <option :value="''">-- Filter Filiale --</option> -->
                            <option v-for="store in stores" :key="store.id" :value="store.id">{{ store.name }}</option>
                          </select>
                        </div>   
                      </div>
                    </div>
                    
                    <div class="col-md-2">
                      <button class="btn btn-primary btn-sm mr-1" @click="loadPenner()"><i class="fas fa-fw fa-sync"></i></button>
                      <button class="btn btn-secondary btn-sm" @click="exportPenner()"><i class="fas fa-fw fa-file-export"></i> Export</button>
                    </div>
                  </div>
              </div>

              <div class="card-body">
                  <div class="table-responsive">
                              <table class="table table-hover table-sm">
                                  <thead>
                                      <th>
                                          <a href="#" @click.prevent="changeSort('ordernumber')">Artikelnummer</a>
                                          <span v-if="this.params.sort_field == 'ordernumber' && this.params.sort_direction == 'asc'" >&uarr;</span>
                                          <span v-if="this.params.sort_field == 'ordernumber' && this.params.sort_direction == 'desc'" >&darr;</span>
                                      </th>
                                      <th>
                                          <a href="#" @click.prevent="changeSort('name')">Artikelname</a>
                                          <span v-if="this.params.sort_field == 'name' && this.params.sort_direction == 'asc'" >&uarr;</span>
                                          <span v-if="this.params.sort_field == 'name' && this.params.sort_direction == 'desc'" >&darr;</span>
                                      </th>
                                      <th><a href="#" @click.prevent="changeSort('producer')">Hersteller</a>
                                          <span v-if="this.params.sort_field == 'producer' && this.params.sort_direction == 'asc'" >&uarr;</span>
                                          <span v-if="this.params.sort_field == 'producer' && this.params.sort_direction == 'desc'" >&darr;</span>
                                      </th>
                                      <th>Flags</th>
                                  </thead>
                                  <tbody>
                                      <tr v-for="(article) in articles.data" :key="article.id">
                                        <td>{{article.ordernumber}}</td>
                                        <td>{{article.name}}</td>
                                        <td>{{article.producer}}</td>
                                        <th>
                                          <span v-if="article.ads == 1" class="badge badge-pill badge-primary">ADS</span>
                                          <span v-if="article.na == 1" class="badge badge-pill badge-danger">z.Z.n.l.</span>
                                        </th>
                                      </tr>
                                  </tbody>
                              </table>
                            </div>
              </div>
              <div class="card-footer">
                <pagination class="float-left" :data="articles" @pagination-change-page="loadPenner" :limit="3"></pagination>                        
                <select class="float-right form-control-sm" v-model="params.per_page">
                    <option value="25">25 Artikel</option>
                    <option value="50">50 Artikel</option>
                    <option value="75">75 Artikel</option>
                    <option value="100">100 Artikel</option>
                    <option value="125">125 Artikel</option>
                    <option value="150">150 Artikel</option>
                </select>
                <p v-if="notEmptyObject(articles)" class="float-right mr-2">Anzeige Eintrag {{ articles.meta.from }} - {{ articles.meta.to }} von {{ articles.meta.total }}</p>
              </div>
              
        </div>
        
      </div>
    </div>
</div>
</template>

<script>
import moment from 'moment'
export default {
  data(){
    return{
      producers:[],
      stores:{},
      params: {
        start_date: moment().subtract(30, 'days').format('YYYY-MM-DD'),
        stop_date: moment().format('YYYY-MM-DD'),
        excluded_producers: [],
        store_id: '',
        sort_field: 'ordernumber',
        sort_direction: 'desc',
        per_page: 25
      },
      articles: {},
      page: 1,
    }
  },
 
  methods:{

    changeSort(field) {
        if(this.params.sort_field === field) {
            this.params.sort_direction = this.params.sort_direction == 'asc' ? 'desc' : 'asc';

        }
        else{
            this.params.sort_field = field;
            this.params.sort_direction = 'asc';
        }
        this.loadPenner();
    },

    exportPenner(){
      this.axios
        .get("/articles/penner/export", { 
            params: {
                ...this.params
            },
            responseType: 'arraybuffer'
        })
        .then((response) => {
            this.downloadFile(response)
            // this.articles = response.data
            //this.$router.push('/articles');
        })
        .catch(() => {
            this.$swal({
                icon: "error",
                title: "Oops...",
                text: "Da ist etwas schief gelaufen",
            });
        });
    },

    downloadFile(response){

            var mimetype = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
            var extension = 'xlsx';

            var newBlob = new Blob([response.data], {type: mimetype})

            // IE doesn't allow using a blob object directly as link href
            // instead it is necessary to use msSaveOrOpenBlob
            if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(newBlob)
            return
            }

            // For other browsers:
            // Create a link pointing to the ObjectURL containing the blob.
            const data = window.URL.createObjectURL(newBlob)
            var link = document.createElement('a')
            link.href = data
            link.download = 'Pennerexport.' + extension
            link.click()
            setTimeout(function () {
            // For Firefox it is necessary to delay revoking the ObjectURL
            window.URL.revokeObjectURL(data)
            }, 100)

        },


    loadPenner(page){
      if(page == undefined)
      {
          page = this.page 
      }
      this.axios
        .get("/articles/penner", { 
            params: {
                page: page,
                ...this.params
            }
        })
        .then((response) => {
            this.articles = response.data
            //this.$router.push('/articles');
        })
        .catch(() => {
            this.$swal({
                icon: "error",
                title: "Oops...",
                text: "Da ist etwas schief gelaufen",
            });
        });
    },

    loadProducers(){
      this.axios
            .get("/producers", {
              params:{
                per_page: 1000,
              }
            })
            .then((response) => {
                this.producers = response.data.data;
                //this.groups = response.data.data[0].groups;
            })
            .catch(() => {
                this.$swal({
                    icon: "error",
                    title: "Oops...",
                    text: "Da ist etwas schief gelaufen",
                });
            });
    },

    loadStores(){
      this.axios
            .get("/stores", {
              params:{
                per_page: 1000,
              }
            })
            .then((response) => {
                this.stores = response.data.data;
                this.params.store_id = this.stores[0].id;
                this.loadPenner();
                //this.groups = response.data.data[0].groups;
            })
            .catch(() => {
                this.$swal({
                    icon: "error",
                    title: "Oops...",
                    text: "Da ist etwas schief gelaufen",
                });
            });
    }
  },

  created() {
    this.loadProducers();
    this.loadStores();
  }
}
</script>

<style>

</style>